<template>
  <div id="app">
    <keep-alive>
      <router-view style="width: 100%;height: 100%" />
    </keep-alive>
    <!-- <router-view style="width: 100%;height: 100%" /> -->
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
   
}
.el-menu-item{
      color: black !important;
   }
</style>
