import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/HomePage',
    name: 'HomePage',
    component: () => import('../views/HomePage.vue'),
  },
  {
    path: '/ItemList',
    name: 'ItemList',
    component: () => import('../views/ItemList/index.vue'),
  },
  {
    path: '/itemInfo',
    name: 'itemInfo',
    component: () => import('../views/ItemList/itemInfo.vue'),
  },
  {
    path: '/itemInfoVideo',
    name: 'itemInfoVideo',
    component: () => import('../views/ItemList/itemInfoVideo.vue'),
  },
 
]


const router = new VueRouter({
  routes
})

export default router
